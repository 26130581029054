<template>
  <div>
    <div class="mx-auto w-full">
      <div class="bg-yellow-500 px-6 py-2 shadow flex justify-center">
        <div class="flex items-baseline">
          <img
            class="mx-auto w-12 flex-none"
            src="~assets/images/logo/dlto.svg"
            alt="deelotto"
          />
          <!-- <span
            class="text-xl font-lg text-center font-semibold text-black flex-none"
          >
            deelotto
          </span> -->
        </div>
      </div>
    </div>
    <div class="mx-auto w-full bg-white p-6 shadow">
      <div class="flex flex-row justify-center space-x-4">
        <div class="basis-2/2 md:basis-6/6 lg:basis-8/8">
          <div class="text-sm">
            หากเป็นสมาชิกเว็บไซต์ deelotto เดิม อยู่แล้ว
            <NuxtLink to="/" class="text-yellow-800">กรุณาเข้าสู่ระบบ</NuxtLink>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
