<template>
  <div class="font-mono w-full px-0">
    <GlobalsHeaderRegister></GlobalsHeaderRegister>
    <slot />
  </div>
</template>

<script setup>
useHead({
  title: "deelotto",
  meta: [
    {
      name: "viewport",
      content:
        "width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no",
    },
  ],
  meta: [{ name: "title", content: "deelotto" }],
  meta: [{ name: "description", content: "deelotto เว็บหวย" }],
});
import { ref } from "vue";
</script>
<style>
html {
  -webkit-text-size-adjust: none;
  touch-action: manipulation;
}
.mt-50 {
  margin-top: 13rem;
}
.bg-yellow-500 {
  background: url("~/assets/images/bg-pattern/white.webp") !important;
  background-repeat: repeat;
  background-size: 250px !important;
}
</style>
